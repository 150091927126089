import React from 'react';
import { SpotlightList } from './SpotlightList';
import { radio, mixes, playlists } from '../data/music';

export const Home = () => {
  return (
    <section className='container-spotlight' key={'spotlight'}>
      <SpotlightList key={'radio'} category={radio}></SpotlightList>
      <SpotlightList key={'mixes'} category={mixes}></SpotlightList>
      <SpotlightList key={'playlists'} category={playlists}></SpotlightList>
    </section>
  );
};
