import React, { useRef } from "react";
import emailjs from "@emailjs/browser";

export const About = () => {
  const emailInput = useRef();
  const successMsg = useRef();

  const sendDataSubs = (e) => {
    e.preventDefault();

    const expReg =
      /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;
    const isValid = expReg.test(emailInput);

    if (isValid === true) {
      emailjs
        .sendForm(
          "service_5r8l5a9",
          "template_0neh0rf",
          e.target,
          "r4xDL1XHHOwG9S6FB"
        )
        .then(
          (result) => {
            successMsg.current.classList.add("success");
            successMsg.current.innerHTML = "<h5>SUCCESS! thank you :)</h5>";
          },
          (error) => {
            successMsg.current.classList.add("error");
            successMsg.current.innerHTML =
              "<h5>ERROR! try to send again :(</h5>";
          }
        );
    } else {
      emailInput.current.value = "Email value error";
    }
  };
  return (
    <section className='about'>
      <div className='page'>
        <h2 className='page-title sc-about'>About</h2>
        <p>
          Hello, good day. Allow me to introduce myself as a Disc Jockey and
          selector. My musical focus revolves around genres such as Soul, Funk,
          Modern Soul, Rare Groove, and Jazz. I firmly believe in the
          authenticity of music, which is why I meticulously curate mostly
          original vinyl records that capture the natural essence of each piece.
        </p>

        <p>
          I've had the pleasure of showcasing my sessions at various venues,
          most notably for four years at the Café Berlin Music Club in Madrid.
          There, I had the opportunity to share the stage with renowned
          international artists like Folamour, Hugo LX, and Nickodemous.
          Additionally, I've collaborated with radio stations in Madrid,
          Barcelona, Glasgow and London.
        </p>

        <p>
          Currently based in Barcelona, I've had the chance to collaborate on
          listening sessions at Curtis and Salvadiscos.
        </p>

        <p>
          I invite you to explore some of my previous sessions, which also
          include curated Spotify playlists that are regularly updated with new
          discoveries.
        </p>
        <p className='text-subscribe'>
          For upcoming sessions, you can subscribe and receive email
          notifications 😊
        </p>

        <div className='form-subscribe' ref={successMsg}>
          <form onSubmit={sendDataSubs}>
            <input
              type='text'
              name='email_from'
              placeholder='e-mail@here.com'
              ref={emailInput}
            />
            <input type='submit' value='👍' name='subscribe' />
          </form>
        </div>

        <div className='about-social'>
          <ul>
            <li>
              <a
                href='https://www.instagram.com/juanfalibene/'
                target='_blank'
                rel='noreferrer'
              >
                Instagram
              </a>
            </li>
            <li>
              <a
                href='https://soundcloud.com/juanfalibene'
                target='_blank'
                rel='noreferrer'
              >
                Soundcloud
              </a>
            </li>
            <li>
              <a
                href='https://www.mixcloud.com/juanfalibene/'
                target='_blank'
                rel='noreferrer'
              >
                Mixcloud
              </a>
            </li>
            <li>
              <a
                href='https://open.spotify.com/user/11101659890?si=1ac0e969172c4b07'
                target='_blank'
                rel='noreferrer'
              >
                Spotify
              </a>
            </li>
            <li>
              <a
                href='https://juanfalibene.com'
                target='_blank'
                rel='noreferrer'
              >
                Website
              </a>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};
