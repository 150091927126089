import React, { useState } from "react";
import { mixes } from "../data/music";
import { AudioPlayer } from "./player/AudioPlayer";
import { VideoPlayer } from "./player/VideoPlayer";
import { BrowserView, MobileView } from "react-device-detect";

export const Mixes = () => {
  const [player, setPlayer] = useState("");
  const [loadPlayer, setLoadPlayer] = useState(false);
  const [videoPlayer, setVideoPlayer] = useState(false);

  // funcion playEmbed y setState
  const playEmbed = (embed, id, title) => {
    let seshId = id;
    let seshEmbed = embed;
    let seshTitle = title;

    if (seshEmbed.includes("soundcloud") || seshEmbed.includes("mixcloud")) {
      setLoadPlayer(true);
      setVideoPlayer(false);
    }

    if (seshEmbed.includes("youtube")) {
      setVideoPlayer(true);
      setLoadPlayer(false);
    }

    let player = {
      id: seshId,
      embed: seshEmbed,
      title: seshTitle,
    };

    setPlayer(player);
  };

  //funcion close video player y setState
  const closeVideoPlayer = () => {
    setVideoPlayer(false);
  };

  return (
    <section className={videoPlayer === true ? "mixes video" : "mixes"}>
      <div className={videoPlayer === true ? "page full-page" : "page"}>
        <BrowserView>
          {loadPlayer === true && videoPlayer === false && (
            <div className='player-desktop'>
              <AudioPlayer
                title={player.title}
                embed={player.embed}
                key={player.id}
              />
            </div>
          )}
        </BrowserView>
        {videoPlayer === true && loadPlayer === false && (
          <div className='player-video'>
            <VideoPlayer
              title={player.title}
              embed={player.embed}
              key={player.id}
            />

            <button className='close-player-video' onClick={closeVideoPlayer}>
              Close
            </button>
          </div>
        )}

        {loadPlayer === false && videoPlayer === false && (
          <h2 className='page-title sc-mixes'>Mixes</h2>
        )}

        {videoPlayer === false && (
          <div className='container-radio'>
            {mixes.map((sesh) => {
              return (
                <button
                  key={sesh.id}
                  onClick={() => playEmbed(sesh.embed, sesh.id, sesh.title)}
                  className='sesh-button mixes'
                >
                  <article>
                    <MobileView>
                      {loadPlayer === true && player.id === sesh.id && (
                        <div className='player-mobile'>
                          <AudioPlayer
                            title={player.title}
                            embed={player.embed}
                            key={player.id}
                          />
                        </div>
                      )}
                    </MobileView>
                    <img
                      src={sesh.cover}
                      alt={sesh.title}
                      onClick={() => playEmbed(sesh.embed, sesh.id, sesh.title)}
                    />
                    <h3 className='sesh-title'>{sesh.title}</h3>
                    <h4 className='sesh-date'>
                      {sesh.place} - {sesh.date}
                    </h4>
                    <ul className='sesh-genres'>
                      {sesh.genres.map((genre, index) => (
                        <li key={index} className='sesh-genre'>
                          {genre}
                        </li>
                      ))}
                    </ul>
                  </article>
                </button>
              );
            })}
          </div>
        )}
      </div>
    </section>
  );
};
