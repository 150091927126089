export const radio = [
  {
    id: "r007",
    title: "NTS Radio: Heatwave Mixtape",
    place: "Barcelona",
    date: "09.08.24",
    embed:
      "https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1895404959&color=%23384336&auto_play=true&hide_related=true&show_comments=false&show_user=false&show_reposts=false&show_teaser=false",
    embed_home:
      "https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1895404959&color=%23120b0d&auto_play=true&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true",
    url: "https://www.nts.live/shows/heatwave/episodes/heatwave-heatwave-strikes-again-and-it-will-never-be-the-same-again-9th-august-2024",
    cover: "./img/r007.jpg",
    genres: ["Jazz", "Soul", "Disco"],
    category: "radio",
  },
  {
    id: "r001",
    title: "NTS Radio: Sounds, Healing, mixtape",
    place: "Barcelona",
    date: "07.04.23",
    embed:
      "https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1485705940&color=%23384336&auto_play=true&hide_related=true&show_comments=false&show_user=false&show_reposts=false&show_teaser=false",
    embed_home:
      "https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1485705940&color=%23120b0d&auto_play=true&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true",
    url: "https://www.nts.live/shows/heartbreak-2023/episodes/heartbreak-2023-sounds-healing-mixtape-7th-april-2023",
    cover: "./img/r001.jpeg",
    genres: ["Soul", "Funk", "Disco"],
    category: "radio",
  },
  {
    id: "r005",
    title: "NOODS Radio: Espressoul",
    place: "London",
    date: "29.11.22",
    embed:
      "https://player-widget.mixcloud.com/widget/iframe/?hide_cover=1&light=1&autoplay=1&feed=%2FNoodsRadio%2Fespressoul-29th-november-22%2F",
    url: "https://noodsradio.com/shows/espressoul-29th-november-22",
    cover: "./img/r005.jpeg",
    genres: ["Soul", "Funk", "Jazz-Rock"],
    category: "radio",
  },
  {
    id: "r003",
    title: "Radio Buena Vida: Sounds, Healing, Mixtape w/ Juan Falibene",
    place: "Glasgow",
    date: "27.01.23",
    embed:
      "https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1435133236&color=%23384336&auto_play=true&hide_related=true&show_comments=false&show_user=false&show_reposts=false&show_teaser=false",
    url: "https://soundcloud.com/radiobuenavida/sounds-healing-mixtape-w-juan-falibene-radio-buena-vida-270123/sets",
    cover: "./img/r004.jpeg",
    genres: ["Soul", "Funk", "Jazz"],
    category: "radio",
  },
  {
    id: "r006",
    title: "NTS Radio: I´ll Call it Verano",
    place: "Barcelona",
    date: "18.08.22",
    embed:
      "https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1326992341&color=%23384336&auto_play=true&hide_related=true&show_comments=false&show_user=false&show_reposts=false&show_teaser=false",
    url: "https://www.nts.live/shows/summer-of-love-2022/episodes/i-ll-call-it-verano-18th-august-2022",
    cover: "./img/r006.jpeg",
    genres: ["Soul", "Funk", "Classic Disco", "70´s"],
    category: "radio",
  },
  {
    id: "r005",
    title: "NOODS Radio: I´ll Call It Morning Soul",
    place: "London",
    date: "12.10.22",
    embed:
      "https://player-widget.mixcloud.com/widget/iframe/?hide_cover=1&light=1&autoplay=1&feed=%2FNoodsRadio%2Fill-call-it-morning-soul%2F",
    url: "https://noodsradio.com/shows/i-ll-call-it-morning-soul-12th-october-22",
    cover: "./img/r003.jpeg",
    genres: ["Soul", "Funk", "Jazz"],
    category: "radio",
  },
  {
    id: "r003",
    title: "Radio Buena Vida: Espressoul w/ Juan Falibene",
    place: "Glasgow",
    date: "19.03.23",
    embed:
      "https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1473439849&color=%23384336&auto_play=true&hide_related=true&show_comments=false&show_user=false&show_reposts=false&show_teaser=false",
    url: "https://soundcloud.com/radiobuenavida/espressoul-w-juan-falibene-radio-buena-vida-190323",
    cover: "./img/r002.jpeg",
    genres: ["Soul", "Funk", "Free-Jazz"],
    category: "radio",
  },
];

export const mixes = [
  {
    id: "m001",
    title: "Disco Between Cafe",
    place: "Madrid",
    date: "04.08.19",
    embed:
      "https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/668604836&color=%23384336&auto_play=true&hide_related=true&show_comments=false&show_user=false&show_reposts=false&show_teaser=false",
    embed_home:
      "https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/668604836&color=%23120b0d&auto_play=true&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true",
    url: "https://soundcloud.com/juanfalibene/disco-between-cafe",
    cover: "./img/m001.jpeg",
    genres: ["Disco-House", "Disco-Funk", "Disco"],
    category: "mixes",
  },
  {
    id: "m002",
    title: "Tuesnight Selection @ Café Berlín",
    place: "Madrid",
    date: "10.11.16",
    embed:
      "https://player-widget.mixcloud.com/widget/iframe/?hide_cover=1&light=1&autoplay=1&feed=%2Fjuanfalibene%2Ftuesnight-selection-caf%C3%A9-berl%C3%ADn-10-11%2F",
    url: "https://www.mixcloud.com/juanfalibene/tuesnight-selection-caf%C3%A9-berl%C3%ADn-10-11/",
    cover: "./img/m002.jpeg",
    genres: ["Nudisco", "Indie-Dance", "House"],
    category: "mixes",
  },
  {
    id: "m003",
    title: "La Radio live #090 Juan Falibene",
    place: "Barcelona",
    date: "04.10.17",
    embed: "https://www.youtube.com/embed/5BiBmhEs_iM?si=y1DZaxLEMyYqd8pt",
    url: "https://www.youtube.com/watch?v=5BiBmhEs_iM&ab_channel=LaRadio",
    cover: "./img/m003.jpeg",
    genres: ["Soul", "Funk", "Disco"],
    category: "mixes",
  },
  {
    id: "m004",
    title: "Juan Falibene @ Clot Warehouse by C/C Radio",
    place: "Barcelona",
    date: "23.12.20",
    embed: "https://www.youtube.com/embed/MtrPOt_9jz4?si=Eb-Tq7iDMT1d_DHS",
    url: "https://www.youtube.com/watch?v=MtrPOt_9jz4&t=2s&ab_channel=PoblenouCommunityRadio",
    cover: "./img/m004.jpeg",
    genres: ["House", "Funk", "Disco"],
    category: "mixes",
  },
  {
    id: "m005",
    title: "TCCBR from Café Pavón",
    place: "Madrid",
    date: "02.11.18",
    embed: "https://www.youtube.com/embed/K5GpaYMqfs0?si=LAp-ieqIgs9u12Kp",
    url: "https://www.youtube.com/watch?v=K5GpaYMqfs0&t=1s&ab_channel=JuanIgnacioFalibene",
    cover: "./img/m005.jpeg",
    genres: ["Soul", "Funk", "Jazz-Funk"],
    category: "mixes",
  },
];

export const playlists = [
  {
    id: "p001",
    title: "Josealfredo #018",
    place: "Madrid",
    date: "01.02.18",
    embed:
      "https://open.spotify.com/embed/playlist/5k3M5kwIwpTKJGSC1rXEIK?utm_source=generator",
    embed_home:
      'https://open.spotify.com/embed/playlist/5k3M5kwIwpTKJGSC1rXEIK?utm_source=generator" width="100%" height="100%" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture',
    url: "https://open.spotify.com/playlist/5k3M5kwIwpTKJGSC1rXEIK?si=f22d57ebc7a845ee",
    cover: "./img/p001.jpg",
    genres: ["Jazz", "Soul", "Modern-Soul"],
    category: "playlists",
  },
  {
    id: "p003",
    title: "Club Vermut Radio🍸📻 #01",
    place: "San Antonio de Areco",
    date: "27.06.22",
    embed:
      "https://open.spotify.com/embed/playlist/5CcaOxzmaWy0Opr8fMZW2p?utm_source=generator",
    url: "https://open.spotify.com/playlist/5CcaOxzmaWy0Opr8fMZW2p?si=9966d5c680834bb1",
    cover: "./img/p003.jpg",
    genres: ["Neo-Soul", "Soul", "Hip-Hop"],
    category: "playlists",
  },
  {
    id: "p002",
    title: "MAZU Cantina🌴",
    place: "Las Palmas Gran Canarias",
    date: "16.09.21",
    embed:
      "https://open.spotify.com/embed/playlist/2XcPkDqLPhe82RNRQHvBD4?utm_source=generator",
    url: "https://open.spotify.com/playlist/2XcPkDqLPhe82RNRQHvBD4?si=a60841dad0184620",
    cover: "./img/p002.jpg",
    genres: ["Jazz", "Brasil", "Bossa"],
    category: "playlists",
  },
  {
    id: "p004",
    title: "Pajaritos Mojados",
    place: "Madrid",
    date: "08.03.19",
    embed:
      "https://open.spotify.com/embed/playlist/4qlHSNPDqeBIHlrR5AIk2J?utm_source=generator",
    url: "https://open.spotify.com/playlist/4qlHSNPDqeBIHlrR5AIk2J?si=e8e32c6c2a0b4a40",
    cover: "./img/p004.jpg",
    genres: ["Disco", "Dance", "Boogie"],
    category: "playlists",
  },
];
