import React, { useState } from "react";
import { playlists } from "../data/music";
import { SpotifyPlayer } from "./player/SpotifyPlayer";
import { BrowserView, MobileView } from "react-device-detect";

export const Playlist = () => {
  const [player, setPlayer] = useState("");
  const [loadPlayer, setLoadPlayer] = useState(false);

  // funcion playEmbed y setState
  const playEmbed = (embed, id, title) => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    let seshId = id;
    let seshEmbed = embed;
    let seshTitle = title;

    let player = {
      id: seshId,
      embed: seshEmbed,
      title: seshTitle,
    };

    setLoadPlayer(true);
    setPlayer(player);
  };

  return (
    <section className='radio'>
      <div className='page'>
        <BrowserView>
          {loadPlayer === true && (
            <div className='player-desktop'>
              <SpotifyPlayer
                title={player.title}
                embed={player.embed}
                key={player.id}
              />
            </div>
          )}
        </BrowserView>

        {loadPlayer === false && (
          <h2 className='page-title sc-playlists'>Playlist</h2>
        )}

        <div className='container-radio'>
          {playlists.map((sesh) => {
            return (
              <button
                key={sesh.id}
                onClick={() => playEmbed(sesh.embed, sesh.id, sesh.title)}
                className='sesh-button playlist'
              >
                <article>
                  <MobileView>
                    {loadPlayer === true && player.id === sesh.id && (
                      <div className='player-mobile'>
                        <SpotifyPlayer
                          title={player.title}
                          embed={player.embed}
                          key={player.id}
                        />
                      </div>
                    )}
                  </MobileView>
                  <img
                    src={sesh.cover}
                    alt={sesh.title}
                    onClick={() => playEmbed(sesh.embed, sesh.id, sesh.title)}
                  />
                  <h3 className='sesh-title'>{sesh.title}</h3>
                  <h4 className='sesh-date'>
                    {sesh.place} - {sesh.date}
                  </h4>
                  <ul className='sesh-genres'>
                    {sesh.genres.map((genre, index) => (
                      <li key={index} className='sesh-genre'>
                        {genre}
                      </li>
                    ))}
                  </ul>
                </article>
              </button>
            );
          })}
        </div>
      </div>
    </section>
  );
};
