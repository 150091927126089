import React from "react";

export const Footer = () => {
  return (
    <footer>
      <p>
        <a href='https://juanfalibene.com' rel='noreferrer'>
          juanfalibene.com
        </a>
      </p>
    </footer>
  );
};
